import { render, staticRenderFns } from "./EditJobsTable.vue?vue&type=template&id=19cf325a&lang=pug"
import script from "./EditJobsTable.vue?vue&type=script&lang=ts"
export * from "./EditJobsTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QRange from 'quasar/src/components/range/QRange.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QExpansionItem,QTable,QBtn,QSpace,QMenu,QList,QItem,QCheckbox,QItemSection,QIcon,QInput,QTr,QTh,QBadge,QPopupEdit,QItemLabel,QTooltip,QSelect,QDate,QRange,QTd,QBtnGroup});
